// Landing.js
import { connect } from 'react-redux';
import getMeData from '../selector/getMeData.js';
import getOperationData from '../selector/getOperationData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getModalData from '../selector/getModalData.js';
import getIsRemoteConfigMerged from '../selector/getIsRemoteConfigMerged.js';
import addModal from '../action/addModal.js';
import mergeMeData from '../action/mergeMeData.js';
import removeModals from '../action/removeModals.js';
import Landing from '../component/Landing.jsx';
import {
  NEED_LANDING_COUNTRIES,
  UNDER_AGE_EXIT_URL,
  PREVIEW_PRESET,
} from '../RemoteConfigKeys.js';
import {
  CONFIG_PRIORITY_CONFIGURE,
  CONFIG_PRIORITY_PRESENCE_CLIENT,
} from '../resource/configPriority.js';

const mapStateToProps = state => {
  const token = getMeData(state, 'token');
  const status = getModalData(state, 'LandingPageModal', 'status');
  const isDisplaying = 'display' === status;

  const priorityGroups = getOperationData(
    state,
    ['config'],
    'configurationGroups'
  );
  const usingRemoteConfigPriority = priorityGroups?.[
    CONFIG_PRIORITY_PRESENCE_CLIENT
  ]
    ? CONFIG_PRIORITY_PRESENCE_CLIENT
    : CONFIG_PRIORITY_CONFIGURE;
  const isRemoteConfigMerged = getIsRemoteConfigMerged(
    state,
    usingRemoteConfigPriority
  );

  const userCountry = getMeData(state, 'country');
  const needLandingCountries =
    getRemoteConfigData(state, NEED_LANDING_COUNTRIES) || [];

  return {
    isInNeedLandingCountries: needLandingCountries.includes(userCountry),
    token,
    underAgeExitUrl: getRemoteConfigData(state, UNDER_AGE_EXIT_URL),
    isDisplaying,
    isRemoteConfigMerged,
    shouldVerifyAge: getRemoteConfigData(state, PREVIEW_PRESET) !== 'clear',
  };
};

const mapDispatchToProps = dispatch => {
  return {
    open: () =>
      dispatch(
        addModal({
          id: 'LandingPageModal',
          isLowerThanAll: true,
        })
      ),
    close: () => {
      dispatch(mergeMeData({ field: 'hasAgeVerified', value: true }));
      return dispatch(
        removeModals({ ids: ['LandingPageModal'], transitionStatus: 'closing' })
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
